import React from 'react';
import { Col, Row, Button, Modal, } from 'react-bootstrap';

function SwitchBrandVerifierModal(props) {

    return (
        <>
            <Modal show={props.show} centered size='md' >
                <Modal.Body>
                    <Row className='g-0 fs-14'>
                        <Col xs={12} className=''>
                            <div class="warning-container">
                                <div class="warning-background">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 384.2 384.36">
                                        <path d="M142.18,192.17c-2.47-2-4-3.12-5.28-4.41Q74.29,125.2,11.74,62.58C-4.33,46.46-3.8,23,12.72,8.69a35.75,35.75,0,0,1,46.73.07c1.88,1.64,3.64,3.43,5.41,5.19q61.52,61.53,123,123.08a42.92,42.92,0,0,1,3.5,4.72c2.27-2.16,3.72-3.47,5.1-4.85Q259.06,74.32,321.66,11.74c16.1-16.06,39.7-15.54,53.88.94a35.78,35.78,0,0,1,.26,46.38c-1.72,2-3.62,3.91-5.51,5.79q-61.5,61.55-123.06,123a38.31,38.31,0,0,1-4.69,3.44c2.06,2.21,3.24,3.56,4.5,4.82Q309.44,258.58,371.87,321c10.57,10.54,14.88,22.8,10.8,37.31-7.11,25.23-38.07,34.34-57.92,17.2-1.77-1.52-3.41-3.19-5.05-4.84L196.3,247.22c-1.28-1.28-2.6-2.5-4.13-4-1.48,1.4-2.82,2.59-4.09,3.86Q125.5,309.7,62.92,372.26c-12.65,12.64-28.68,15.51-43.38,8a35.91,35.91,0,0,1-10.4-55.78c1.44-1.61,3-3.14,4.52-4.67q61.68-61.72,123.4-123.41C138.34,195.09,139.86,194.06,142.18,192.17Z" />
                                    </svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" style={{ width: '100px', height: '60px' }} viewBox="0 0 54.76 215.65">
                                        <g id="Layer_1" data-name="Layer 1">
                                            <path d="M27.09,0C13.68,0,1.79,9.52.34,22.91c-.89,8.17.18,16.61.84,24.9,2.05,25.82,4.38,51.62,6.49,77.43.82,10.13,8.74,18.15,18.63,18.51,10.21.38,19.2-7,20.18-17.17,1.75-18.35,3.11-36.73,4.64-55.1,1.12-13.4,1.91-26.84,3.45-40.2C56.49,14.78,43.76,0,27.09,0Z" />
                                            <path d="M27.14,167.74a24,24,0,1,0,24,24A24,24,0,0,0,27.14,167.74Z" />
                                        </g>
                                    </svg>
                                </div>
                                <div class="warning-shadow"></div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <h5 className='text-center fs-14 fw-bold'>
                                Are you sure?
                            </h5>
                            <h5 className='text-center fs-12 mb-3 text-blue'>
                                You want to switch a different brand !
                            </h5>
                        </Col>
                        <Col xs={12}>
                            <Row className='g-2'>
                                <Col xs={12}>
                                    <Button className='fs-14 w-100 py-3 bg-light-blue text-truncate rounded' onClick={() => props.onCloseButton('YES')}>
                                        Yes
                                    </Button>
                                </Col>
                                <Col xs={12}>
                                    <Button className='fs-14 w-100 py-3 bg-light-blue text-truncate rounded' onClick={() => props.onCloseButton('NO')}>
                                        No
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}



export default (SwitchBrandVerifierModal);
