import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Row, Placeholder } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { fetchStoreByKeyCode } from '../../slice/dashBoardSlice';
import { useDispatch } from 'react-redux';
import noImage from '../../assets/images/no-image.png';
import { checkAuthentication } from '../../utils';

function Cart() {

  const baseUrl = localStorage.getItem('baseUrl');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [store, setStore] = useState({});
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || {});
  const [orderedItemsQty, setOrderedItemsQty] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [swipeStates, setSwipeStates] = useState([]);
  const [cookieStore, setCookieStore] = useState(Cookies.get('store') ? JSON.parse(Cookies.get('store')) : {});

  useEffect(() => {
    if (checkAuthentication()) {

      if (cookieStore && cookieStore.storeKey) {
        dispatch(fetchStoreByKeyCode(cookieStore.storeKey)).then((response) => {
          setStore(response.payload || {});
        })
      }
    } else {
      navigate('/');
    }
  }, [cookieStore]);

  const inc = (item) => {
    let cartClone = cart;
    let items = [];
    if (cartClone && cartClone.items && cartClone.items.length) {
      cartClone.items.map((prd) => {
        if (prd.productId === item.productId) {
          prd.qty = prd.qty + 1;
        }
      })

      items = JSON.parse(JSON.stringify(cartClone.items));
    }
    localStorage.setItem('cart', JSON.stringify(cartClone));
    setCart({ ...cart, items });
  }

  const dec = (item) => {
    let cartClone = cart;
    let items = [];
    if (cartClone && cartClone.items && cartClone.items.length) {
      cartClone.items.map((prd, index) => {
        if (prd.productId === item.productId) {
          if (prd.qty && prd.qty > 1) {
            prd.qty = prd.qty - 1;
          } else if (prd.qty == 1) {
            cartClone.items.splice(index, 1);
          }
        }
      })
      items = JSON.parse(JSON.stringify(cartClone.items));
    }
    localStorage.setItem('cart', JSON.stringify(cartClone));
    setCart({ ...cart, items });
  }


  useEffect(() => {
    refreshCart();
    const initialSwipeStates = Array.from({ length: cart.items && cart.items.length }, () => ({
      initialPosition: null,
      currentPosition: null,
      swipeDirection: null,
    }));
    setSwipeStates(initialSwipeStates);
  }, [cart]);

  const refreshCart = () => {
    let totalOrderItems = 0;
    let orderTotal = 0;
    if (cart && cart.items && cart.items.length > 0) {
      let subTotal = 0;
      let totalGst = 0;
      Promise.all(
        cart.items.map((item) => {
          let itemPrice = parseFloat(item.price).toFixed(2);
          if (item.isPriceGstInc === 'Y') {
            const itemGst = item.perGst ? parseFloat((item.price * item.perGst / (100)).toFixed(2)) : 0;
            subTotal = subTotal + parseFloat((item.price - itemGst).toFixed(2) * item.qty);
            totalGst = totalGst + parseFloat((itemGst * item.qty).toFixed(2));
            itemPrice = itemPrice - itemGst;
          } else {
            const itemGst = item.perGst ? parseFloat((item.price * item.perGst / 100).toFixed(2)) : 0;
            subTotal = subTotal + parseFloat((item.price * item.qty).toFixed(2));
            totalGst = totalGst + parseFloat((itemGst * item.qty).toFixed(2));
          }
          item.prodSubTotal = parseFloat(itemPrice).toFixed(2);
          totalOrderItems = totalOrderItems + item.qty;
          return item
        })).then(() => {
          orderTotal = ((parseFloat(subTotal) + parseFloat(totalGst)) + parseFloat(orderTotal)).toFixed(2);
          const taxPercent = (((totalGst / orderTotal) * 100) / 2).toFixed(2);
          if (totalOrderItems !== orderedItemsQty) {
            setCart({ ...cart, orderTotal: orderTotal, subTotal: subTotal, taxPercent: parseInt(taxPercent), cgst: parseFloat(totalGst / 2).toFixed(2), sgst: parseFloat(totalGst / 2).toFixed(2), })
            localStorage.setItem('cart', JSON.stringify({ ...cart, orderTotal: orderTotal, totalGst: totalGst, cgst: parseFloat(totalGst / 2).toFixed(2), sgst: parseFloat(totalGst / 2).toFixed(2), subTotal: subTotal, taxPercent: parseInt(taxPercent) }));
            setOrderedItemsQty(totalOrderItems);
          }
          setTimeout(() => {
            setDataLoaded(true);
          }, 2000);
        })
    } else {
      setTimeout(() => {
        setDataLoaded(true);
      }, 2000);

      const order = {
        "orderDate": "",
        "orderTotal": "0",
        "totalGst": "0",
        "discount": "0",
        "discountType": "DEFAULT",
        "paymentStatus": "UNPAID",
        "deliveryStatus": "NONE",
        "amountPaid": "0",
        "status": "OPEN",
        "deliveryMethod": cart.deliveryMethod,
        "paymentMethod": "CASH",
        "customerId": "",
        "storeId": store ? store.id : "",
        "deliveryAddressId": "",
        "items": []
      }

      localStorage.setItem('cart', JSON.stringify(order));
      setCart(order)
      navigate('/home');
    }

  }

  const handleSwipeStart = (index, event) => {
    const { clientX, touches } = event;
    const touchX = touches ? touches[0].clientX : clientX;
    setSwipeStates(prevStates => {
      const newState = [...prevStates];
      newState[index].initialPosition = touchX;
      return newState;
    });
  };

  const handleSwipeMove = (index, event) => {
    const { clientX, touches } = event;
    const touchX = touches ? touches[0].clientX : clientX;
    setSwipeStates(prevStates => {
      const newState = [...prevStates];
      newState[index].currentPosition = touchX;
      return newState;
    });
  };

  const handleSwipeEnd = (index) => {
    setSwipeStates(prevStates => {
      const newState = [...prevStates];
      const { initialPosition, currentPosition } = newState[index];
      if (initialPosition && currentPosition) {
        const deltaX = currentPosition - initialPosition;
        newState[index].swipeDirection = deltaX > 0 ? 'right' : 'left';
      }
      newState[index].initialPosition = null;
      newState[index].currentPosition = null;
      return newState;
    });
  };

  // const overlayStyle = (index) => {
  //   const swipeDirection = swipeStates[index] ? swipeStates[index].swipeDirection : null;
  //   return {
  //     backdropFilter: swipeDirection === 'left' ? 'blur(8px)' : 'blur(0px)',
  //     backgroundColor: 'transparent',
  //     position: 'absolute',
  //     top: 0,
  //     right: 0,
  //     opacity: swipeDirection === 'left' ? '1' : '0',
  //     width: swipeDirection === 'left' ? '100%' : '0%',
  //     height: '100%',
  //     zIndex: 1,
  //     display: 'flex',
  //     alignItems: "center",
  //     justifyContent: "end",
  //     borderRadius: "0.375rem",
  //     overflow: "hidden",
  //     transition: 'width 0.3s ease, backdropFilter 0.3s ease, opacity 0.3s ease ',
  //   };
  // };

  const overlayStyle = (index) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const swipeDirection = swipeStates[index] ? swipeStates[index].swipeDirection : null;

    const baseStyles = {
      backdropFilter: swipeDirection === 'left' ? 'blur(8px)' : 'blur(0px)',
      WebkitBackdropFilter: swipeDirection === 'left' ? 'blur(8px)' : 'blur(0px)',
      backgroundColor: 'transparent',
      position: 'absolute',
      top: 0,
      right: 0,
      opacity: swipeDirection === 'left' ? '1' : '0',
      width: swipeDirection === 'left' ? '100%' : '0%',
      height: '100%',
      zIndex: 1,
      display: 'flex',
      alignItems: "center",
      justifyContent: "end",
      borderRadius: "0.375rem",
      overflow: "hidden",
      transition: 'width 0.3s ease, backdropFilter 0.3s ease, opacity 0.3s ease ',
    };

    if (isSafari && swipeDirection === 'left') {
      baseStyles.backgroundColor = 'rgba(255, 255, 255, 0.8)';
      baseStyles.WebkitBackdropFilter = 'blur(8px)';
    }

    return baseStyles;
  };

  const moveStyle = (index) => {
    const swipeDirection = swipeStates[index] ? swipeStates[index].swipeDirection : null;
    return {
      marginRight: swipeDirection === 'left' ? '1.5rem' : '-100px',
      transition: 'marginRight 0.3s ease ',
    };
  };

  const handleCheckout = () => {
    if (Cookies.get('guest') === 'true' || Cookies.get('guest') === true) {
      localStorage.setItem('currentStep', 'cart');
      navigate('/enternumber')
    } else {
      localStorage.setItem('currentStep', 'checkout');
      if (cart.deliveryMethod === 'DELIVERY') {
        navigate('/checkout');
      } else {
        navigate('/payment');
      }
    }
  }

  const handleDetailItem = (item) => {
    localStorage.setItem('prdId', item.productId);
    navigate('/productdetails?id=' + item.productId)
  };

  const handleDeleteItem = (index) => {
    const newItems = [...cart.items];
    newItems.splice(index, 1);
    setCart({ ...cart, items: newItems });
    localStorage.setItem('cart', JSON.stringify({ ...cart, items: newItems }));
  };

  return (
    <>

      <Row className='g-0 justify-content-center cart'>
        <Col xs={12} md={12} lg={6}>
          <Row className='g-0 p-3 '>
            <Col xs={12} className='d-flex align-items-center'>
              <h5 className='mb-0 w-100 pe-4 fs-6'>Cart</h5>
              <Button size='sm' className='bg-transparent text-dark border-0'>
                <i class="fas fa-close" onClick={() => navigate('/home')}></i>
              </Button>
            </Col>
          </Row>
          <Row className='g-0 p-3 pt-2 card-height d-block'>



            {dataLoaded === true ?
              <>
                {cart.items.map((item, index) => {
                  return (
                    <>
                      <Col xs={12} key={index}>
                        <Card className='cardItem mb-2'
                          onTouchStart={(event) => handleSwipeStart(index, event)}
                          onTouchMove={(event) => handleSwipeMove(index, event)}
                          onTouchEnd={() => handleSwipeEnd(index)}
                          onMouseDown={(event) => handleSwipeStart(index, event)}
                          onMouseMove={(event) => handleSwipeMove(index, event)}
                          onMouseUp={() => handleSwipeEnd(index)}
                        >

                          <div style={overlayStyle(index)}>
                            <span style={moveStyle(index)} className=' d-flex justify-content-end align-items-center'>
                              <Button size='' onClick={() => handleDetailItem(item)} className='bg-light border-0 text-blue me-2 bg-white rounded fs-6' >
                                <i class="fas fa-eye"></i>
                              </Button>
                              <Button size='' onClick={() => handleDeleteItem(index)} className='bg-light border-0 text-red bg-white rounded fs-6' >
                                <i class="fas fa-trash"></i>
                              </Button>
                            </span>
                          </div>

                          <Card.Body className='p-2'>
                            <Row className='g-0 fs-14'>
                              <Col xs={12} sm={12} >
                                <Image width={50} height={50} className='rounded me-3 object-fit-cover float-start' src={item.icon ? (baseUrl + item.icon) : noImage} />
                                <Row className='g-0'>
                                  <Col xs={12} className='fs-12 text-truncate'>
                                    {item.name}
                                  </Col>
                                  <Col xs={8} sm={9} className='d-flex align-items-center'>
                                    <span className="fs-12 me-auto pe-2 d-flex flex-wrap align-items-center">
                                      <span className='d-flex align-items-center'>
                                        <i class="fas fa-indian-rupee-sign fs-12 text-blue"></i>{(item.prodSubTotal * (item.qty)).toFixed(2)}
                                      </span>
                                      <div className='divider'></div>
                                      <span className='text-blue d-flex align-items-center'>
                                        <i class="fas fa-indian-rupee-sign fs-12 text-blue"></i>{item.prodSubTotal} * {item.qty}
                                      </span>
                                    </span>
                                    {/* <span className="fs-12 me-auto pe-3 d-flex align-items-center">
                                      <span className=''>
                                        <i class="fas fa-indian-rupee-sign fs-12 text-blue"></i>{item.price}
                                      </span>
                                      <div className='divider'></div>
                                      <span className=''>
                                        <i class="fas fa-indian-rupee-sign fs-12 text-blue"></i>{item.price * (item.qty)}
                                      </span>
                                    </span> */}
                                    {/* <span className=''><i class="fas fa-indian-rupee-sign fs-12 text-blue"></i>{item.price}</span> */}
                                  </Col>
                                  <Col xs={4} sm={3} className='d-flex align-items-center justify-content-end'>
                                    <Card className='card-item addQty_card'
                                    // style={{ marginTop: '-6px' }}
                                    >
                                      <Button className='border-0 bg-transparent text-dark fs-12 ms-1' onClick={() => dec(item)}>
                                        <i class="fas fa-minus"></i>
                                      </Button>
                                      <Form.Control className='bg-transparent p-0 shadow-none rounded text-center fs-14' placeholder="" value={item.qty} />
                                      <Button className='border-0 bg-transparent text-dark fs-12 me-1' onClick={() => inc(item)}>
                                        <i class="fas fa-plus"></i>
                                      </Button>
                                    </Card>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  )
                }
                )}
              </> :
              <Placeholder animation="glow" as='row' className='g-0'>
                {cart && cart.items && cart.items.map((item) => {
                  return (
                    <Placeholder xs={12} className='rounded mb-2' style={{ height: '68px' }} />
                  )
                }
                )}
              </Placeholder>}
            {dataLoaded === true ?
              <>
                <Col xs={12} md={12} className='mt-3'>
                  <h5 className='fs-14 fw-bold'>Bill Details</h5>
                </Col>
                <Col xs={12}>
                  <Card className='cardItem mb-2' >
                    <Card.Body className='p-2'>
                      <Row className='g-0 fs-14'>
                        <Col xs={12} className=''>
                          <ul className='list-group list-group-flush mb-0 fs-14 w-100'>
                            <li className="list-group-item p-0">
                              <Row className='g-0 d-flex align-items-center mt-2'>
                                <Col xs={12}>
                                  <Row className='g-0 fs-14 mb-1'>
                                    <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                      Item total
                                    </Col>
                                    <Col md={6} xs={6} className="d-flex justify-content-end align-items-center">
                                      <i class="fas fa-indian-rupee-sign text-blue"></i>{cart.subTotal ? parseFloat(cart.subTotal).toFixed(2) : 0}
                                    </Col>
                                  </Row>
                                  {/* <Row className='g-0 fs-12 mb-1'>
                                    <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                      Total Discount
                                    </Col>
                                    <Col md={6} xs={6} className="d-flex justify-content-end align-items-center text-green">
                                      <i class="fas fa-indian-rupee-sign text-blue fs-10"></i>0
                                    </Col>
                                  </Row> */}
                                  <Row className='g-0 fs-12 mb-1'>
                                    <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                      CGST - {cart.taxPercent}%
                                    </Col>
                                    <Col md={6} xs={6} className="d-flex justify-content-end align-items-center">
                                      <i class="fas fa-indian-rupee-sign text-blue"></i>{parseFloat(cart?.cgst).toFixed(2)}
                                    </Col>
                                  </Row>
                                  <Row className='g-0 fs-12 mb-1'>
                                    <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                      SGST - {cart.taxPercent}%
                                    </Col>
                                    <Col md={6} xs={6} className="d-flex justify-content-end align-items-center">
                                      <i class="fas fa-indian-rupee-sign text-blue"></i>{parseFloat(cart?.sgst).toFixed(2)}
                                    </Col>
                                  </Row>

                                  {/* <Row className='g-0 fs-12 mb-1'>
                                    <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                      Total
                                    </Col>
                                    <Col md={6} xs={6} className="d-flex justify-content-end align-items-center text-green">
                                      <i class="fas fa-indian-rupee-sign text-blue"></i>{cart?.orderTotal}
                                    </Col>
                                  </Row> */}

                                  {/* <Row className='g-0 fs-12 mb-2'>
                                    <Col md={6} xs={6} className="d-flex justify-content-start align-items-center text-decoration-underline text-blue">
                                      Packaging Charges
                                    </Col>
                                    <Col md={6} xs={6} className="d-flex justify-content-end align-items-center">
                                      <i class="fas fa-indian-rupee-sign text-blue"></i>0.00
                                    </Col>
                                  </Row> */}
                                  <Row className='g-0 fs-14 mb-2'>
                                    <hr className='mb-2' style={{ borderTop: '1px dashed' }} />
                                    <Col md={6} xs={6} className="d-flex justify-content-start  align-items-center fw-bold">
                                      Total
                                    </Col>
                                    <Col md={6} xs={6} className="d-flex justify-content-end  align-items-center fw-bold">
                                      <i class="fas fa-indian-rupee-sign text-blue" ></i>{cart?.orderTotal}
                                    </Col>
                                  </Row>
                                  {/* <Row className='g-0 fs-14 mb-2'>
                                    <hr className='mb-2' style={{ borderTop: '1px dashed' }} />
                                    <Col md={6} xs={6} className="d-flex justify-content-start  align-items-center fw-bold">
                                      To Pay
                                    </Col>
                                    <Col md={6} xs={6} className="d-flex justify-content-end  align-items-center fw-bold">
                                      <i class="fas fa-indian-rupee-sign text-blue" ></i>{cart.orderTotal ? cart.orderTotal : 0}
                                    </Col>
                                  </Row> */}
                                </Col>
                                {/* <Col md={12} className='mt-1'>
                                  <Alert variant='success' className='fs-12 py-1 rounded-0 mb-2'>
                                    You have saved &nbsp;<i class="fas fa-indian-rupee-sign text-blue " ></i>0 on the bill
                                  </Alert>
                                </Col> */}
                                <Col xs={12} md={12}>
                                  <Row className='g-0 fs-12 mb-2'>
                                    <Col xs={12} className='d-flex align-items-start '>
                                      <span className='me-2'><i class="fa-solid fa-list-check text-green"></i></span>
                                      <div className='w-100 align-self-start'>
                                        <p className='mb-0'>Review your Order and address details to avoid cancellations</p>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </> :
              <Placeholder animation="glow" as='row' className='g-0'>
                <Placeholder xs={12} className='mb-2 mt-3' style={{ width: '25%' }} />
                <Placeholder xs={12} className='rounded mb-2' style={{ height: '220px' }} />
              </Placeholder>}
            {/* <Col xs={12}>
              <Card className='cardItem mb-2' >
                <Card.Body className='p-2'>
                  <Row className='g-0 fs-14'>
                    <Col xs={12} className=''>
                      <ul className='list-group list-group-flush mb-0 fs-14 w-100'>
                        <li className="list-group-item p-0">
                          <Row className='g-0 d-flex align-items-center mt-2'>
                            <Col xs={12}>
                              <Row className='g-0 fs-12 mb-1'>
                                <Col md={6} xs={6} className="d-flex justify-content-start align-items-center">
                                  <span style={{ width: '20px' }}><i class="fas fa-file-lines fs-12"></i></span>Item total
                                </Col>
                                <Col md={6} xs={6} className="d-flex justify-content-end align-items-center">
                                  <i class="fas fa-indian-rupee-sign text-blue fs-12"></i>{cart.orderTotal ? cart.orderTotal : 0}
                                </Col>
                              </Row>
                              <Row className='g-0 fs-12 mb-1'>
                                <Col md={6} xs={6} className="d-flex justify-content-start align-items-center">
                                  <span style={{ width: '20px' }}><i class="fas fa-motorcycle fs-12"></i></span>Delivery charge
                                </Col>
                                <Col md={6} xs={6} className="d-flex justify-content-end align-items-center text-blue">
                                  Free
                                </Col>
                              </Row>
                              <Row className='g-0 fs-12 mb-1'>
                                <Col md={6} xs={6} className="d-flex justify-content-start align-items-center">
                                  <span style={{ width: '20px' }}><i class="fas fa-bag-shopping fs-12 me-1"></i></span>Packaging charge
                                </Col>
                                <Col md={6} xs={6} className="d-flex justify-content-end align-items-center">
                                  <i class="fas fa-indian-rupee-sign text-blue"></i>0.00
                                </Col>
                              </Row>
                              <Row className='g-0 fs-14 mb-1 mt-2'>
                                <Col md={6} xs={6} className="d-flex justify-content-start  align-items-center fw-bold">
                                  Grand total
                                </Col>
                                <Col md={6} xs={6} className="d-flex justify-content-end  align-items-center fw-bold">
                                  <i class="fas fa-indian-rupee-sign text-blue fs-12" ></i>{cart.orderTotal ? cart.orderTotal : 0}
                                </Col>
                              </Row>
                              <Row className='g-0 fs-14 mb-1'>
                                <hr className='mb-2' style={{ borderTop: '1px dashed' }} />
                                <Col md={6} xs={6} className="d-flex justify-content-start  align-items-center fw-bold text-blue">
                                  Your total savings
                                </Col>
                                <Col md={6} xs={6} className="d-flex justify-content-end  align-items-center fw-bold text-blue">
                                  <i class="fas fa-indian-rupee-sign fs-12" ></i>0.00
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> */}
            {dataLoaded === true ?
              <>
                <Col xs={12} md={12} className='mt-3'>
                  <h5 className='fs-14 fw-bold'>Cancellation Policy</h5>
                </Col>
                <Col xs={12}>
                  <Card className='cardItem mb-2' >
                    <Card.Body className='p-2'>
                      <Row className='g-0 py-2'>
                        <Col xs={12} md={12}>
                          <span className='d-flex flex-wrap align-items-center'>
                            <div className='me-auto pe-3 '>
                              {/* <h4 className="fs-14 fw-bold text-start mb-1">
                            Cancellation Policy
                          </h4> */}
                              <p className="fs-12 mb-0 text-start">Orders cannot be cancelled once packed for delivery. In case of unexpected delays, a refund will be provided, if applicable.</p>
                            </div>
                          </span>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </> :
              <Placeholder animation="glow" as='row' className='g-0'>
                <Placeholder xs={12} className='mb-2 mt-3' style={{ width: '25%' }} />
                <Placeholder xs={12} className='rounded mb-2' style={{ height: '70px' }} />
              </Placeholder>}
          </Row>
          <Row className='g-0 position-relative'>
            <Col xs={12} lg={6} className='px-3 btn-position'>
              {dataLoaded === true ?
                <Button onClick={() => handleCheckout()} className='w-100 py-3 foot-btn bg-light-blue text-truncate' disabled={cart.items && cart.items.length > 0 ? false : true}>
                  Checkout
                </Button>
                :
                <Placeholder animation="glow" as='row' className='g-0'>
                  <Placeholder.Button xs={12} className='rounded' style={{ height: '53px' }} />
                </Placeholder>}
            </Col>
          </Row>
        </Col>
      </Row>

    </>
  );
}


export default Cart;