import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Button, Image, Placeholder, } from 'react-bootstrap';
import mapImg from '../../assets/images/realtime-update.jpg'
import AddAndEditAddress from './AddAndEditAddress';
import { useDispatch } from 'react-redux';
import { getCustomerAddressList } from '../../slice/dashBoardSlice';
import { useNavigate } from 'react-router-dom';
import { checkAuthentication } from '../../utils';

function CheckOut() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [addeditshow, setAddeditshow] = useState(false);
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')));
    const [addressList, setAddressList] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const onClickButton = () => {
        setAddeditshow(true);
    }

    useEffect(() => {
        if (checkAuthentication()) {
            fetcCustomerAddressList();
        } else {
            navigate('/home')
        }
    }, []);

    const fetcCustomerAddressList = async () => {
        let addressList = []
        await dispatch(getCustomerAddressList()).then((response) => {
            if (response.payload) {
                if (Array.isArray(response.payload) && response.payload.length > 0) {
                    setAddressList(response.payload);
                    setCart({ ...cart, deliveryAddressId: response.payload[0].id })
                    localStorage.setItem('cart', JSON.stringify({ ...cart, deliveryAddressId: response.payload[0].id }));
                    setTimeout(() => {
                        setDataLoaded(true);
                    }, 2000);
                } else {
                    setAddeditshow(true);
                }
            } else {
                setTimeout(() => {
                    setDataLoaded(true);
                }, 2000);
            }
            addressList = response.payload;
        });
        return addressList;
    }
    const onCloseButton = async (refresh) => {
        setAddeditshow(false);
        const addressList = await fetcCustomerAddressList();
        if (addressList.length === 0) {
            navigate('/cart');

        }
    };

    // const handleCheckBox = (e) => {
    //     const { value } = e.target;
    //     localStorage.setItem('cart', JSON.stringify({ ...cart, deliveryMethod: value }));
    //     setCart({ ...cart, deliveryMethod: value });
    // }

    const chooseAddress = (addrId) => {
        localStorage.setItem('cart', JSON.stringify({ ...cart, deliveryAddressId: addrId }));
        setCart({ ...cart, deliveryAddressId: addrId });
    }

    return (
        <>
            <Row className='g-0 justify-content-center checkout'>
                <Col xs={12} md={12} lg={6}>
                    <Row className='g-0 p-3 '>
                        <Col xs={12} className='d-flex align-items-center'>
                            <Button size='sm' href='/home' className='bg-transparent text-dark border-0'>
                                <i class="fas fa-arrow-left"></i>
                            </Button>
                            <h5 className='mb-0 w-100 text-center pe-4 fs-6'>Checkout</h5>
                        </Col>
                    </Row>
                    <Row className='g-0 p-3 pt-2 card-height d-block'>
                        {/* <Col xs={12} className='d-flex align-items-center'>
                            <h5 className='fs-14 fw-bold'>Delivery method</h5>
                        </Col>
                        <Col xs={12} md={12} >
                            <Card className='cardItem'>
                                <Card.Body className='pb-0'>
                                    <Row className='g-0 fs-14'>
                                        <Col md={6} xs={6} className="mb-2">
                                            <Form.Check type='radio' label='Door Delivery' value="DELIVERY" checked={cart.deliveryMethod == "DELIVERY"} onChange={(e) => handleCheckBox(e)} />
                                        </Col>
                                        <Col md={6} xs={6} className="mb-2">
                                            <Form.Check type='radio' label='Pick up' value="PICK" checked={cart.deliveryMethod == "PICK"} onChange={(e) => handleCheckBox(e)} />
                                        </Col>
                                        <Col md={6} xs={6} className="mb-2">
                                            <Form.Check type='radio' label='Dine In' value="DINE" checked={cart.deliveryMethod == "DINE"} onChange={(e) => handleCheckBox(e)} />
                                        </Col>
                                        <Col md={6} xs={6} className="mb-2">
                                            <Form.Check type='radio' label='Drive In' value="DRIVE" checked={cart.deliveryMethod == "DRIVE"} onChange={(e) => handleCheckBox(e)} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col> */}
                        {dataLoaded === true ?
                            <>
                                {cart.deliveryMethod === "L" ?
                                    <>
                                        <Col xs={12} className={`d-flex align-items-center ${cart.deliveryMethod === "L" ? "" : "mt-3"}`}>
                                            <h5 className='fs-14 fw-bold'>Location</h5>
                                        </Col>

                                        <Col xs={12} md={12} >
                                            <Card className='cardItem'>
                                                <Card.Body className='p-1 mapImg'>
                                                    <Image width='100%' className='' src={mapImg} />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </>
                                    : cart.deliveryMethod === "DELIVERY" ?
                                        <>
                                            <Col xs={12}>
                                                <Row className='g-0 text-left'>
                                                    <Col xs={8} md={8} className={`d-flex align-items-center ${cart.deliveryMethod === "DELIVERY" ? "" : "mt-3"}`}>
                                                        <h5 className='fs-14 fw-bold'>Enter Delivery Address</h5>
                                                    </Col>
                                                    <Col xs={4} md={4} className='d-flex align-items-center justify-content-end'>
                                                        <Button size='sm' className='bg-transparent text-dark fw-bold border-0 me-2' onClick={onClickButton}>
                                                            <i class="fas fa-plus"></i>
                                                        </Button>
                                                        {/* <Button size='sm' className='bg-transparent text-dark fw-bold border-0' onClick={onClickButton}>
                                                    <i class="fas fa-pen-to-square"></i>
                                                </Button> */}
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col xs={12} md={12}  >
                                                {addressList && addressList.length > 0 && addressList.map((addr) => {
                                                    return (
                                                        <Card className={`cardItem mb-2 ${cart.deliveryAddressId === addr.id ? 'bg-yellow' : ''}`} role='button' onClick={() => chooseAddress(addr.id)}>
                                                            <Card.Body className='px-2'>
                                                                <Card.Title className='fs-14 '>{addr.personName}</Card.Title>
                                                                <hr className={`m-0 mb-2 ${cart.deliveryAddressId === addr.id ? 'border-dark' : ''}`} />
                                                                <Card.Text className='fs-12 fst-italic' >
                                                                    <Row className='g-0'>
                                                                        <Col xs={12} className='d-flex align-items-start'>
                                                                            <span className='me-2'>
                                                                                <i class="fa-solid fa-location-dot pe-1"></i>
                                                                            </span>
                                                                            <span className="w-100">{addr.line1 + ' ' + addr.line2}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Text>
                                                                <Card.Text className='fs-12 fst-italic' >
                                                                    <Row className='g-0'>
                                                                        <Col xs={12} className='d-flex align-items-start'>
                                                                            <span className='me-2 fs-11'>
                                                                                <i class="fa-solid fa-phone pe-1"></i>
                                                                            </span>
                                                                            <span className="w-100">+91 {addr.mobileNumber}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    )
                                                })}
                                            </Col>
                                        </> : null
                                }
                            </> :
                            <Placeholder animation="glow" as='row' className='g-0'>
                                <Placeholder xs={12} className='mb-2' style={{ width: '25%' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '118px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '118px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '118px' }} />
                                <Placeholder xs={12} className='rounded mb-2' style={{ height: '118px' }} />
                            </Placeholder>}
                    </Row>
                    <Row className='g-0 position-relative'>
                        <Col xs={12} lg={6} className='px-3 btn-position'>
                            {dataLoaded === true ?
                                <Button href='/payment' disabled={!cart.deliveryAddressId} className='w-100 py-3 foot-btn bg-light-blue text-truncate'>
                                    Proceed to Pay
                                </Button> :
                                <Placeholder animation="glow" as='row' className='g-0'>
                                    <Placeholder.Button xs={12} className='rounded' style={{ height: '53px' }} />
                                </Placeholder>}
                        </Col>
                    </Row>
                </Col>
                {/* } */}
            </Row>

            <AddAndEditAddress addeditshow={addeditshow} onCloseButton={onCloseButton} />
        </>
    )
}

export default (CheckOut);


