import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Card, Form, FloatingLabel, Button, } from "react-bootstrap";

const FilterModal = ({ ...props }) => {
  const [searchBy, setSearchBy] = useState({ ...props.filters });

  useEffect(() => {
    if (props.showModal) {
      setSearchBy({ ...props.filters });
    }
  }, [props.showModal]);

  const handlePriceFilter = (event) => {
    const numericValue = event.target.value.replace(/\D/g, ""); // Removes all non-numeric characters
    setSearchBy({ ...searchBy, filters: { ...searchBy.filters, [event.target.name]: numericValue } });
  };

  const handleSortBy = (event) => {
    setSearchBy({ ...searchBy, [event.target.name]: event.target.value });
  };

  const submit = () => {
    props.handleSubmit(searchBy);
  };

  const discardChanges = () => {
    setSearchBy({ ...searchBy, sortBy: "", filters: {} });
  };


  return (
    <Modal
      show={props.showModal}
      fullscreen={false}
      centered
      size="md"
      className="my_modal"
      onHide={props.hideModal}
    >
      {/* <span className="bg-img1" style={{ zIndex: "1" }}></span> */}
      <Modal.Header style={{ zIndex: "12" }} closeButton>

        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-0  ">
          <Col xs={12} md={12} lg={12} className="mb-3">
            <h6>Price Range </h6>
            <Card className="border-0 bg-transparent">
              <Card.Body className="p-0 " style={{ zIndex: "12" }}>
                {" "}
                <Row className="g-2 ">
                  <Col xs={6} md={6} lg={6}>
                    <FloatingLabel
                      className="shadow-box"
                      controlId="floatingInputGrid"
                      label="Min Price"
                    >
                      <Form.Control type="number" placeholder="Min Price" name="minPrice" value={searchBy.filters.minPrice || ""} onChange={handlePriceFilter} />
                    </FloatingLabel>
                  </Col>
                  <Col xs={6} md={6} lg={6}>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Max Price "
                    >
                      <Form.Control
                        className="shadow-box"
                        type="number"
                        placeholder="Max Price"
                        name="maxPrice"
                        value={searchBy.filters.maxPrice || ""}
                        onChange={handlePriceFilter}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={12} lg={12}>
            <h6>Sort by </h6>
            <Card className="border-0 bg-transparent">
              <Card.Body className="p-0" style={{ zIndex: "12" }}>
                {" "}
                <FloatingLabel controlId="floatingSelect" label="Sorting" >
                  <Form.Select aria-label="Sorting" name="sortBy" onChange={handleSortBy}>
                    <option>Sort by</option>
                    <option selected={searchBy.sortBy === 'asc'} value="asc">Low to High</option>
                    <option selected={searchBy.sortBy === 'dsc'} value="dsc">High to Low</option>
                    {/* <option value="2">Price high to low</option> */}
                  </Form.Select>
                </FloatingLabel>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="p-0" style={{ zIndex: "12" }}>
        <Row className="g-2 w-100">
          <Col xs={6} md={6} lg={6}>
            <Button
              className="w-100 py-3 foot-btn bg-light-blue text-truncate btn btn-primary"
              variant="outlined"
              onClick={discardChanges}
            >
              Discard
            </Button>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <Button
              className="w-100 py-3 foot-btn bg-light-green text-truncate"
              variant="primary"
              onClick={submit}
            >
              Apply
            </Button>
          </Col>
        </Row>
      </Modal.Footer>

    </Modal>
  );
};

export default FilterModal;
