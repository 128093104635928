import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Modal, Row } from "react-bootstrap";
import noProductImage from '../../assets/images/no-image.png';

const DownloadProductImgModal = (props) => {
    const [isVideoDownloaded, setIsVideoDownloaded] = useState(false);

    useEffect(() => {
        if (props.showModal) {
            setIsVideoDownloaded(false)
        }
    }, [props.showModal]);

    const downloadDocument = (url) => {
        fetch(url).then(res => res.blob()).then(file => {
            let tempUrl = URL.createObjectURL(file);
            const aTag = document.createElement("a");
            aTag.href = tempUrl;
            aTag.download = url.replace(/^.*[\\\/]/, '');
            document.body.appendChild(aTag);
            aTag.click();
            URL.revokeObjectURL(tempUrl);
            aTag.remove();
        }).catch(() => {
            alert("Failed to download file!");
        });
        onDocumentSelect(url)
    };

    const onDocumentSelect = (url) => {
        const foundIndex = props.fileList.findIndex(e => e.fileSrc === url);
        if (foundIndex > -1) {
            const fileListClone = [...props.fileList];
            fileListClone[foundIndex].documentDownloaded = 'Y';
            props.setFileList(fileListClone)
        }
    };

    return (
        <>
            <Modal show={props.showModal} centered size='md' className='my_modal' >
                <Modal.Body>
                    <Row className='g-0 fs-14'>
                        <Col xs={12} className='text-end'>
                            <Button className='bg-transparent border-0 shadow-none text-dark' onClick={props.hideModal}>
                                <i class="fas fa-close"></i>
                            </Button>
                        </Col>
                        <Col xs={12} className="d-flex flex-wrap justify-content-center" style={{ gap: 10 }}>
                            {props.fileList && props.fileList.map((file, indx) => {
                                return (
                                    <Card key={indx} className='cardItem position-relative' style={{ width: 90, pointerEvents: file.documentDownloaded === 'Y' ? 'none' : '' }} >
                                        <Card.Body className='p-2 text-decoration-none' role='button' onClick={() => downloadDocument(file.fileSrc ? file.fileSrc : noProductImage)}>
                                            <Row className='g-0 fs-14'>
                                                <Col xs={12} className=''>
                                                    <div className="position-relative">
                                                        <Image width={60} height={60} className='rounded d-block mx-auto object-fit-cover' src={file.fileSrc} />
                                                        {file.documentDownloaded === 'Y' ?
                                                            <Form.Check className='download-input' type='checkbox' checked={file.documentDownloaded === 'Y'} />
                                                            : null}
                                                    </div>
                                                    <Button variant='primary' className='mt-2 p-0 bg-transparent d-block mx-auto border-0 text-dark'> <i class="fa-solid fa-download"></i></Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                )
                            })}
                            {props.videoUrl ?
                                <Card className='cardItem position-relative' style={{ width: 90, pointerEvents: isVideoDownloaded ? 'none' : '' }} >
                                    <Card.Body className='p-2 text-decoration-none' role='button' onClick={() => { downloadDocument(props.videoUrl); setIsVideoDownloaded(true) }}>
                                        <Row className='g-0 fs-14'>
                                            <Col xs={12} className=''>
                                                <div className="position-relative">
                                                    <video className='pe-none rounded d-block mx-auto object-fit-cover' width={60} height={60} frameBorder="0" allowFullScreen controls="true">
                                                        <source src={props.videoUrl ? props.videoUrl : noProductImage}></source>
                                                    </video>
                                                    {isVideoDownloaded ?
                                                        <Form.Check className='download-input' type='checkbox' checked={isVideoDownloaded} />
                                                        : null}
                                                </div>
                                                <Button variant='primary' className='mt-2 p-0 bg-transparent d-block mx-auto border-0 text-dark' > <i class="fa-solid fa-download"></i></Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card> : null}
                        </Col>

                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DownloadProductImgModal;