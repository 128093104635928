import React from 'react';
import { Col, Row, Button, Modal, } from 'react-bootstrap';

function ExitReviewModal(props) {

    return (
        <>
            <Modal show={props.showExitReviewModal} centered size='md' >
                <Modal.Body>
                    <Row className='g-0 fs-14'>
                        <Col xs={12} className='mt-5 mb-4'>
                            <h5 className='text-center fs-14 fw-bold'>
                                Exit Review ?
                            </h5>
                            <h5 className='text-center fs-12 mb-3 text-blue'>
                                Your feedback is important to us. Are you sure you want to exit ?
                            </h5>
                        </Col>
                        <Col xs={12}>
                            <Button className='fs-14 w-100 py-3 bg-light-blue text-truncate rounded mb-2' onClick={props.onCloseBackReviewModal}>
                                Submit Review
                            </Button>
                        </Col>
                        <Col xs={12}>
                            <Button className='fs-14 w-100 py-3 bg-light-red text-truncate rounded' onClick={props.onCloseExitReviewModal}>
                                Exit Review
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}



export default (ExitReviewModal);
