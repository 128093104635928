import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Form, Modal, FloatingLabel } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { createNewAddress } from '../../slice/dashBoardSlice';
import { states } from '../../utils';

function AddAndEditAddress(props) {

    const dispatch = useDispatch();
    const [addDetails, setAddressDetails] = useState({
        personName: '',
        line1: '',
        mobileNumber: '',
        line2: '',
        stateCode: '03'
    });
    const [isSaveDisabled, setIsSaveDisabled] = useState(true); // state to track button enable/disable

    useEffect(() => {
        if (props.addeditshow) {
            resetFields()
        }
    }, [props.addeditshow])

    useEffect(() => {
        // Enable the Save button if all mandatory fields are filled
        const { personName, line1, mobileNumber } = addDetails;

        // Check if mobile number is valid (exactly 10 digits)
        const isMobileValid = mobileNumber.length === 10;

        // Enable the Save button if all mandatory fields are filled and mobile number is valid
        if (personName && line1 && isMobileValid) {
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }

    }, [addDetails]); // Trigger whenever any field in addDetails changes

    const handleChange = (event) => {
        const { name, value } = event.target;

        // Update the state with the entered value without validation
        if (name === "mobileNumber") {
            const numericValue = value.replace(/\D/g, ""); // Removes all non-numeric characters
            setAddressDetails({ ...addDetails, [name]: numericValue });
        } else {
            setAddressDetails({ ...addDetails, [name]: value });
        }
    };

    const resetFields = () => {
        setAddressDetails({
            personName: '',
            line1: '',
            mobileNumber: '',
            line2: '',
            stateCode: "03"
        });
    };

    const addAddress = async () => {
        if (addDetails.personName && addDetails.line1 && addDetails.mobileNumber) {
            let json = {
                "personName": addDetails.personName,
                "line1": addDetails.line1,
                "line2": addDetails.line2 ? addDetails.line2 : "",
                "mobileNumber": addDetails.mobileNumber ? addDetails.mobileNumber : "",
                "locationLat": "30.746343",
                "locationLong": "75.2718205",
                "status": "ACTIVE",
                ...addDetails
            }
            await dispatch(createNewAddress(json)).then((res) => {
                if (res.payload && !res.payload.error) {
                    props.onCloseButton('refresh');

                }
            })
        }
    }


    return (
        <>

            <Modal show={props.addeditshow} centered size='md' className='my_modal' >
                {/* <Modal.Header >
                    <Modal.Title>Enter Address Details</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <Row className='g-0'>
                                <Col xs={9} className='d-flex align-items-center'>
                                    <h5 className='fs-14 fw-bold mb-0'>
                                        Enter Address Details
                                    </h5>
                                </Col>
                                <Col xs={3} className='text-end'>
                                    <Button className='bg-transparent border-0 shadow-none text-dark' onClick={props.onCloseButton}>
                                        <i class="fas fa-close"></i>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" label={<>Name<span className="text-danger">*</span></>} controlId="floatingTextarea" >
                                <Form.Control name='personName' className='shadow-box' type="text" placeholder="name" onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" label={<>Mobile<span className=" text-danger">*</span></>} controlId="floatingTextarea">
                                <Form.Control
                                    name='mobileNumber'
                                    className='shadow-box'
                                    type="text"
                                    placeholder="Mobile"
                                    value={addDetails.mobileNumber} // Ensure that the value is always numeric
                                    maxLength={10} // Enforce maximum length of 10 digits
                                    onChange={handleChange}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" label={<>Full Address <span className=" text-danger">*</span></>} controlId="floatingTextarea" >
                                <Form.Control name='line1' className='shadow-box' type="text" placeholder="Full Address" onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" label="Landmark" controlId="floatingTextarea" >
                                <Form.Control name='line' className='shadow-box' placeholder='LandMark' type="text" onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" label={<>Town / City / District <span className=" text-danger">*</span></>} controlId="floatingTextarea" >
                                <Form.Control name='city' className='shadow-box' type="text" placeholder="Town / City / District" onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" controlId="floatingSelect" label="State">
                                <Form.Select className='shadow-box form-control' aria-label="Floating label Select State" name='stateCode'>
                                    <option> Select State</option>
                                    {states && states.map((state, index) => {
                                        return (
                                            <option selected={state.code === addDetails.stateCode} key={index} value={state.code}>{state.name}</option>
                                        )
                                    })}
                                    {/* 
                        <option value="Moga">Moga</option>
                        <option value="Mohali">Mohali</option> */}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel className="mb-2" label={<>PIN Code <span className=" text-danger">*</span></>} controlId="floatingTextarea" >
                                <Form.Control name='zipCode' className='shadow-box' type="text" placeholder="PIN Code" onChange={handleChange} />
                            </FloatingLabel>
                        </Col>
                        {/* <Col xs={12}>
                            <FloatingLabel className="mb-2" label="Address Line 2" controlId="floatingTextarea" >
                                <Form.Control name='line2' className='shadow-box' type="text" placeholder="Address Line 2" onChange={handleChange} />
                            </FloatingLabel>
                        </Col> */}

                        <Col xs={12} className='mt-3'>
                            <Button className='fs-14 w-100 py-3 bg-light-green text-truncate rounded' onClick={addAddress}
                                disabled={isSaveDisabled} // Button is disabled based on the state
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                {/* <Modal.Footer className='border-0 pt-0'>
                    <Button
                        variant='primary'
                        className='light-btn bg-light-green text-white'
                        onClick={addAddress}
                        disabled={isSaveDisabled} Button is disabled based on the state
                    >
                        Save
                    </Button>
                    <Button variant='primary' className='light-btn bg-light-blue text-white' onClick={props.onCloseButton}>
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default (AddAndEditAddress);
