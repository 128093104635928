import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Placeholder,
  Row,
} from "react-bootstrap";
import profileImg from "../../assets/images/user.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getUserProfileDetails,
  updateUserProfileDetails,
} from "../../slice/dashBoardSlice";
import OrderConfirmedModal from "../payment/OrderConfirmedModal";
import { states } from "../../utils";

function YourProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [userProfileDetails, setUserProfileDetails] = useState({});
  const [message, setMessage] = useState({
    open: false,
    message: "",
    messageText: "",
    msgDescription: "",
    buttonText: "",
    invoiceNumber: "",
  });

  useEffect(() => {
    setDataLoaded(false);
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    await dispatch(getUserProfileDetails()).then((response) => {
      if (response.payload && !response.payload.error) {
        setUserProfileDetails(response.payload);
        setTimeout(() => {
          setDataLoaded(true);
        }, 2000);
      }
    });
  };

  const updateUserProfile = async () => {
    await dispatch(updateUserProfileDetails({ ...userProfileDetails })).then(
      (response) => {
        if (response.payload && !response.payload.error) {
          setMessage({
            open: true,
            message: "SUCCESS",
            messageText: "Profile updated.",
            msgDescription: "Your profile has been updated.",
            buttonText: "Back to profile",
            invoiceNumber: response.payload.tempInvoiceNo,
          });
        } else {
          setMessage({
            open: true,
            message: "FAILED",
            messageText: "Profile updated failed",
            msgDescription: "Profile updation failed",
            buttonText: "Try Again",
          });
        }
      }
    );
  };

  const handleChange = (event) => {
    // Update the state with the entered value without validation
    if (event.target.name === "mobileNumber") {
      const numericValue = event.target.value.replace(/\D/g, ""); // Removes all non-numeric characters
      setUserProfileDetails({
        ...userProfileDetails,
        [event.target.name]: numericValue,
      });
    } else {
      setUserProfileDetails({
        ...userProfileDetails,
        [event.target.name]: event.target.value,
      });
    }
  };

  const onCloseButton = () => {
    setMessage({
      open: false,
      message: "",
      messageText: "",
      msgDescription: "",
      buttonText: "",
      invoiceNumber: "",
    });
  };


  return (
    <>
      <Row className="g-0 justify-content-center brandInformation">
        <span className="bg-img1" style={{ zIndex: "-1" }}></span>
        <Col xs={12} md={12} lg={6}>
          <Row className="g-0 p-3 ">
            <Col xs={12} className="d-flex align-items-center">
              <Button
                size="sm"
                onClick={() => navigate("/home")}
                className="bg-transparent text-dark border-0"
              >
                <i class="fas fa-arrow-left"></i>
              </Button>
              <h5 className="mb-0 w-100 text-center fs-6 pe-4">Your Profile</h5>
            </Col>
          </Row>
          <Row className="g-0 p-3 pt-2 card-height d-block">
            {dataLoaded === true ? (
              <Col xs={12} md={12} className="">
                <Row className="g-0">
                  <Col xs={12} md={12} className="mb-4 mb-2">
                    <div className="profile_box">
                      <div className="profile_img">
                        <img src={profileImg} alt="Profile" />
                      </div>
                      {/* <input type='file' id='uploadImage' onChange={handleChangeImage} /> */}
                      <Button
                        htmlFor="uploadImage"
                        as="label"
                        className="fs-12 position-absolute translate-middle p-0 border-light rounded-circle profile_edit_btn"
                      >
                        <i class="fas fa-pen"></i>
                      </Button>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <FloatingLabel
                      className="mb-2"
                      label="Name"
                      controlId="floatingTextarea"
                    >
                      <Form.Control
                        name="name"
                        className="shadow-box"
                        type="text"
                        placeholder="Name"
                        value={userProfileDetails.name}
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <FloatingLabel
                      className="mb-2"
                      label="Mobile"
                      controlId="floatingTextarea"
                    >
                      {/* <Form.Control name='mobileNumber' className='shadow-box' type="text" placeholder="Mobile" value={userProfileDetails.mobileNumber} onChange={handleChange} /> */}
                      <Form.Control
                        name="mobileNumber"
                        className="shadow-box"
                        type="text"
                        placeholder="Mobile"
                        value={userProfileDetails.mobileNumber} // Ensure that the value is always numeric
                        maxLength={10} // Enforce maximum length of 10 digits
                        onChange={handleChange}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <FloatingLabel
                      className="mb-2"
                      label="Email"
                      controlId="floatingTextarea"
                    >
                      <Form.Control
                        name="email"
                        className="shadow-box"
                        type="text"
                        placeholder="Email"
                        onChange={handleChange}
                        value={userProfileDetails.email}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <FloatingLabel
                      className="mb-2"
                      label="GST Number"
                      controlId="floatingTextarea"
                    >
                      <Form.Control
                        name="gstNumber"
                        className="shadow-box"
                        type="text"
                        placeholder="GST Number"
                        onChange={handleChange}
                        value={userProfileDetails.gstNumber}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <FloatingLabel
                      className="mb-2"
                      label="Full Address"
                      controlId="floatingTextarea"
                    >
                      <Form.Control
                        name="line1"
                        className="shadow-box"
                        type="text"
                        placeholder="Full Address"
                        onChange={handleChange}
                        value={userProfileDetails.gstNumber}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <FloatingLabel
                      className="mb-2"
                      label="Town / City / District"
                      controlId="floatingTextarea"
                    >
                      <Form.Control
                        name="line1"
                        className="shadow-box"
                        type="text"
                        placeholder="Town / City / District"
                        onChange={handleChange}
                        value={userProfileDetails.gstNumber}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <FloatingLabel className="mb-2" controlId="floatingSelect" label="State">
                      <Form.Select className='shadow-box form-control' aria-label="Floating label Select State" name="stateCode" onChange={handleChange}>
                        <option> Select State</option>
                        {states && states.map((state, index) => {
                          return (
                            <option selected={state.code === userProfileDetails.stateCode} key={index} value={state.code}>{state.name}</option>
                          )
                        })}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={12}>
                    <FloatingLabel
                      className="mb-2"
                      label="PIN Code"
                      controlId="floatingTextarea"
                    >
                      <Form.Control
                        name="line1"
                        className="shadow-box"
                        type="text"
                        placeholder="PIN Code"
                        onChange={handleChange}
                        value={userProfileDetails.gstNumber}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Placeholder animation="glow" as="row" className="g-0">
                <Placeholder
                  xs={12}
                  className="rounded-circle mx-auto d-block mb-4"
                  style={{ width: "70px", height: "70px" }}
                />
                <Placeholder
                  xs={12}
                  className="rounded mb-2"
                  style={{ height: "57px" }}
                />
                <Placeholder
                  xs={12}
                  className="rounded mb-2"
                  style={{ height: "57px" }}
                />
                <Placeholder
                  xs={12}
                  className="rounded mb-2"
                  style={{ height: "57px" }}
                />
                <Placeholder
                  xs={12}
                  className="rounded mb-2"
                  style={{ height: "57px" }}
                />
                <Placeholder
                  xs={12}
                  className="rounded mb-2"
                  style={{ height: "57px" }}
                />
                <Placeholder
                  xs={12}
                  className="rounded mb-2"
                  style={{ height: "57px" }}
                />
                <Placeholder
                  xs={12}
                  className="rounded mb-2"
                  style={{ height: "57px" }}
                />
                <Placeholder
                  xs={12}
                  className="rounded mb-2"
                  style={{ height: "57px" }}
                />
              </Placeholder>
            )}
          </Row>
          <Row className="g-0 position-relative">
            <Col xs={12} lg={6} className="px-3 btn-position">
              {dataLoaded === true ? (
                <Button
                  onClick={updateUserProfile}
                  className="w-100 py-3 foot-btn bg-light-blue text-truncate"
                >
                  Update Profile
                </Button>
              ) : (
                <Placeholder animation="glow" as="row" className="g-0">
                  <Placeholder.Button
                    xs={12}
                    className="rounded"
                    style={{ height: "53px" }}
                  />
                </Placeholder>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <OrderConfirmedModal show={message} onCloseButton={onCloseButton} />
    </>
  );
}

export default YourProfile;
