import React from 'react';
import { Col, Row, Button, Modal, } from 'react-bootstrap';

function ConfirmationModal(props) {

    return (
        <>
            <Modal show={props.show} centered size='md' onHide={props.hideModal}>
                <Modal.Body>
                    <Row className='g-0 fs-14'>
                        <Col xs={12} className=''>
                            {props.show.message && props.show.message !== "SUCCESS" ?
                                <div class="close-container">
                                    <div class="close-background">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 384.2 384.36">
                                            <path d="M142.18,192.17c-2.47-2-4-3.12-5.28-4.41Q74.29,125.2,11.74,62.58C-4.33,46.46-3.8,23,12.72,8.69a35.75,35.75,0,0,1,46.73.07c1.88,1.64,3.64,3.43,5.41,5.19q61.52,61.53,123,123.08a42.92,42.92,0,0,1,3.5,4.72c2.27-2.16,3.72-3.47,5.1-4.85Q259.06,74.32,321.66,11.74c16.1-16.06,39.7-15.54,53.88.94a35.78,35.78,0,0,1,.26,46.38c-1.72,2-3.62,3.91-5.51,5.79q-61.5,61.55-123.06,123a38.31,38.31,0,0,1-4.69,3.44c2.06,2.21,3.24,3.56,4.5,4.82Q309.44,258.58,371.87,321c10.57,10.54,14.88,22.8,10.8,37.31-7.11,25.23-38.07,34.34-57.92,17.2-1.77-1.52-3.41-3.19-5.05-4.84L196.3,247.22c-1.28-1.28-2.6-2.5-4.13-4-1.48,1.4-2.82,2.59-4.09,3.86Q125.5,309.7,62.92,372.26c-12.65,12.64-28.68,15.51-43.38,8a35.91,35.91,0,0,1-10.4-55.78c1.44-1.61,3-3.14,4.52-4.67q61.68-61.72,123.4-123.41C138.34,195.09,139.86,194.06,142.18,192.17Z" />
                                        </svg>
                                    </div>
                                    <div class="close-shadow"></div>
                                </div> :
                                <div class="warning-container">
                                    <div class="warning-background">
                                        <svg viewBox="0 0 489.418 489.418" fill="#000000" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path d="M244.709,389.496c18.736,0,34.332-14.355,35.91-33.026l24.359-290.927c1.418-16.873-4.303-33.553-15.756-46.011   C277.783,7.09,261.629,0,244.709,0s-33.074,7.09-44.514,19.532C188.74,31.99,183.022,48.67,184.44,65.543l24.359,290.927   C210.377,375.141,225.973,389.496,244.709,389.496z" />
                                                <path d="M244.709,410.908c-21.684,0-39.256,17.571-39.256,39.256c0,21.683,17.572,39.254,39.256,39.254   s39.256-17.571,39.256-39.254C283.965,428.479,266.393,410.908,244.709,410.908z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="warning-shadow"></div>
                                </div>


                            }
                        </Col>
                        <Col xs={12}>
                            <h5 className='text-center fs-14 fw-bold'>
                                Cancel Order
                            </h5>
                            <h5 className='text-center fs-12 mb-3 text-blue'>
                                {props.messageText}
                            </h5>
                        </Col>
                        <Col xs={12}>
                            <Button className='fs-14 w-100 py-3 bg-light-blue text-truncate rounded mb-2' onClick={() => props.handleClick('YES')}>
                                Yes
                            </Button>
                        </Col>
                        <Col xs={12}>
                            <Button className='fs-14 w-100 py-3 bg-light-blue text-truncate rounded' onClick={() => props.handleClick('NO')}>
                                No
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}



export default (ConfirmationModal);
