import Cookies from "js-cookie";


function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

export function createSession(token) {
    if (token) {
        const jwt = parseJwt(token);
        const exp = new Date(jwt.exp * 1000);
        localStorage.setItem("EXPIRY", exp.getTime());
    }
}

export const checkAuthentication = () => {
    const token = Cookies.get('E_COMM_TOKEN');
    if (token && isExpired()) {
        invalidateSession();
        return false;
    }
    return (token === "undefined" || token === undefined) ? false : true
};

const invalidateSession = () => {
    Cookies.remove('authenticated');
    Cookies.remove('E_COMM_TOKEN');
    Cookies.remove('store');
    Cookies.remove('location');
    localStorage.clear('EXPIRY');
    localStorage.clear('baseUrl');
};

const isExpired = () => {
    const exp = localStorage.getItem("EXPIRY");
    if (Date.now() <= Number(exp)) {
        return false;
    }
    return true;
}


export const states = [
    { name: "Andhra Pradesh", code: "37" }, { name: "Arunachal Pradesh", code: "12" }, { name: "Assam", code: "18" }, { name: "Bihar", code: "10" },
    { name: "Chattisgarh", code: "22" }, { name: "Goa", code: "30" }, { name: "Gujarat", code: "24" }, { name: "Haryana", code: "06" },
    { name: "Himachal Pradesh", code: "02" }, { name: "Jharkhand", code: "20" }, { name: "Karnataka", code: "29" }, { name: "Kerala", code: "32" },
    { name: "Madhya Pradesh", code: "23" }, { name: "Maharashtra", code: "27" }, { name: "Manipur", code: "14" }, { name: "Meghalaya", code: "17" },
    { name: "Mizoram", code: "15" }, { name: "Nagaland", code: "13" }, { name: "Odisha", code: "21" }, { name: "Punjab", code: "03" },
    { name: "Rajasthan", code: "08" }, { name: "Sikkim", code: "11" }, { name: "Tamil Nadu", code: "33" }, { name: "Telangana", code: "36" },
    { name: "Tripura", code: "16" }, { name: "Uttarakhand", code: "05" }, { name: "Uttar Pradesh", code: "09" }, { name: "West Bengal", code: "19" },
    // Union Territories
    { name: "Andaman and Nicobar Islands", code: "35" }, { name: "Chandigarh", code: "04" },
    { name: "Dadra and Nagra Haveli and Daman and DIU (NEWLY MERGED UT)", code: "26" }, { name: "Jammu & Kashmir", code: "01" },
    { name: "Ladakh", code: "38" }, { name: "Lakshadweep", code: "31" },
    { name: "Puducherry", code: "34" },
    // *****Additional****
    { name: "Delhi", code: "07" }
];